<template>
    <div class="container">
        <template v-if="uploadConf.qiniu_upload">
            <el-upload
                class="upload-demo"
                :action="uploadConf.qiniu_upload"
                :file-list="showFileList"
                :show-file-list="true"
                :on-remove="handleRemoveVideo"
                :on-success="handleVideoSuccess"
                :on-error="handleError"
                :before-upload="beforeAvatarUpload"
                :data="uploadConf"
                :limit="1"
                :on-exceed="handleExceed"
            >
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传不超过200M的文件</div>
            </el-upload>
        </template>
    </div>
</template>

<script>
import {nanoid} from 'nanoid'
import {getUploadConfigApi} from './api'

export default {
    name: 'UploaderVideo',
    data() {
        return {
            showFileList: [],
            fileList: [],
            uploadFile: {
                fileName: '',
                fileSize: '',
                fileUrl: '',
                status: ''
            },
            uploadConf: {},
            uploadType: ''
        }
    },

    props: {
        videoUrl: {
            type: [String, Object, Array]
        }
    },
    watch: {
        videoUrl: {
            immediate: true,
            handler(newV, oldV) {
                if (newV) {
                    if (typeof newV === 'object') {
                        if (newV.url) {
                            newV = JSON.stringify(newV);
                        }
                    }
                    this.showFileList = [JSON.parse(newV)]
                }
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            getUploadConfigApi().then(res => {
                this.uploadType = res.uploadType
                this.uploadConf = res.config
            })
        })
    },
    methods: {
        beforeAvatarUpload: function (file) {
            let fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
            this.uploadConf.key = nanoid() + '.' + fileExtension
            const isLt2M = file.size / 1024 / 1024 < 200
            if (!isLt2M) {
                this.$message.error('文件大小不能超过 200MB!')
                return false
            }
        },
        handleVideoSuccess: function (res, file) {
            // this.fileList.push({
            //   uid: file.uid,
            //   name: file.name,
            //   url: this.uploadConf.domain + '/' + res.key
            // })

            console.log(res, file, '@@@@@@@')

            this.showFileList.push({
                uid: file.uid,
                name: file.name,
                url: this.uploadConf.domain + '/' + res.key
            })
            let data = {
                uid: file.uid,
                name: file.name,
                url: this.uploadConf.domain + '/' + res.key,
                file_size: file.size
            }

            this.$emit('update:videoUrl', data)
        },

        handleRemoveVideo(file, fileList) {
            console.log(fileList)
            // this.fileList = this.fileList.filter(item => item.uid !== file.uid)
            this.showFileList = fileList
            this.$emit('update:videoUrl', '')
        },

        handleError: function (res) {
            this.$message.error({
                message: '上传失败',
                duration: 2000,
                type: 'warning'
            })
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
        }
    }
}
</script>

<style scode></style>
