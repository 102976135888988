<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>

        <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
<!--            <el-form-item prop="category_id" label="选择类目" label-width="100px">-->
<!--                <el-select v-model="formData.category_id" placeholder="请选择">-->
<!--                    <el-option v-for="item in categoryList" :key="item.id" :label="item.name"-->
<!--                               :value="item.id"></el-option>-->
<!--                </el-select>-->
<!--            </el-form-item>-->
            <el-form-item prop="name" label="文章标题">
                <el-input size="small" v-model="formData.title" placeholder="文章标题"></el-input>
            </el-form-item>

            <el-form-item label="外链地址" prop="outbound_links" label-width="100px">
                <el-input size="small" v-model="formData.outbound_links" placeholder="外链地址"></el-input>
            </el-form-item>


            <el-form-item class="art-editor" v-if="formData.type == 1" label="文章内容" prop="content"
                          label-width="100px">
                <Editor :showBtn="true" :content.sync="formData.content"></Editor>
            </el-form-item>

<!--            <el-form-item v-if="formData.type == 2" label="视频" prop="video_url" label-width="100px">-->
<!--                &lt;!&ndash; <file-upload :upload-result="getVideoUrl" :showUrl="this.formData.video_url"></file-upload> &ndash;&gt;-->
<!--                <file-upload-video :videoUrl.sync="formData.video_url"></file-upload-video>-->
<!--            </el-form-item>-->

            <el-form-item label="浏览量" prop="read_count" label-width="100px">
                <el-input-number v-model="formData.read_count" :min="0" label="浏览量"></el-input-number>
            </el-form-item>

            <el-form-item label="排序值" prop="sort" label-width="100px">
                <el-input-number v-model="formData.sort" :min="0" label="排序值"></el-input-number>
            </el-form-item>
            <el-form-item label=" ">
                <el-button size="small" type="" @click="cancel">取消</el-button>
                <el-button size="small" type="primary" @click="save">保存</el-button>
            </el-form-item>
        </el-form>
        <div style="height:40px;"></div>
    </div>
</template>

<script>
import {addArticle, editArticle, detail, getAllCategoryListAPI, getCategoryListAPI} from './api'
import {DICT_SYSTEM_SEX, DICT_SYSTEM_STATUS} from '@/enum/dict'
import Uploader from '@/components/uploader/index.vue'
import Editor from '@/components/editor/index.vue'
import FileUploadImg from '@/components/uploader/uploader-img.vue'
import FileUploadVideo from '@/components/uploader/uploader-video.vue'

export default {
    name: 'AddOrEdit',
    components: {Editor},
    data() {
        return {
            files: [],
            options: [],
            DICT_SYSTEM_SEX,
            DICT_SYSTEM_STATUS,

            formData: {
                title: '',
                sub_title: '',
                category_id: 0,
                read_count: 0,
                type: '1',
                outbound_links: '',
                cover: '',
                desc: '',
                content: '',
                video_url: '',
                sort: 0
            },
            categoryList: [
                {
                    id: 0,
                    name: '请选择'
                }
            ],
            rules: {
                title: [
                    {
                        required: true,
                        message: '文章标题必填',
                        trigger: 'blur'
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '文章类型必选',
                        trigger: 'blur'
                    }
                ],
                category_id: [
                    {
                        required: true,
                        message: '文章类目必选',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },

    mounted() {
        if (this.$route.params.id) {
            this.getAdminDetail()
        }
        this.getNoticeCategoryId()



    },

    methods: {
        aaaa(uploada) {
            console.log('aaa', uploada)
        },
        updateSex(val) {
            this.formData.sex = val
        },
        updateStatus(val) {
            this.formData.status = val
        },
        getUploadUrl({fileUrl}) {
            this.formData.cover = fileUrl
        },
        getVideoUrl({fileUrl}) {
            this.formData.video_url = fileUrl
        },
        save() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    if (this.formData.id) {
                        // 编辑
                        await editArticle(this.formData).then(() => {
                            this.$message.success('编辑成功')
                            this.$router.back()
                        })
                    } else {
                        // 新增
                        await addArticle(this.formData).then(() => {
                            this.$message.success('添加成功')
                            this.$router.back()
                        })
                    }
                } else {
                    return false
                }
            })
        },
        async getAdminDetail() {
            this.formData = await detail(this.$route.params.id)
            if (this.formData.type !== undefined) {
                this.formData.type = this.formData.type.toString()
            }
            // if (this.formData.video_url !== undefined) {
            //     this.formData.video_url = JSON.stringfy(this.formData.video_url)
            // }
            console.log(this.formData)
        },
        async getAllCategoryList() {
            this.categoryList = await getAllCategoryListAPI()
        },


        async getNoticeCategoryId() {
            const res = await getCategoryListAPI({name: '通知通告'})
            console.log(res, '@@@1111')
            this.formData.category_id = res.data[0]?.id
        },


        cancel() {
            this.$router.back(-1)
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }

    .art-editor {
      .el-form-item__content {
        width: 800px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
